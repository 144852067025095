<app-rp-loader></app-rp-loader>
<div class="row">
    <div class="">
        <div class="col-12">

            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
                <span class="breadcrumb m-0">
                    <button class="btn btn-primary d-flex align-items-center" (click)="exportAll()">
                        <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
                    </button>

                </span>

            </div>
        </div>
    </div>
    <!-- <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb> -->
    <div class="col-lg-12">
        <!-- <form> -->
        <div class="row">
            <div class="col-lg-4">
                <div class="mb-3">
                    <label class="form-label">Region</label>
                    <!-- <kendo-multiselect class="kendo-form-control " kendoMultiSelectSummaryTag textField="name"
                        [placeholder]="'Select Region'" valueField="id" [filterable]="true"
                        [(ngModel)]="selectedRegions" [autoClose]="false" [data]="regionsList"
                        (filterChange)="onFilterChange($event)" (valueChange)="valueChange($event)">

                        <ng-template kendoMultiSelectHeaderTemplate>
                            <span class="ms-2 py-1">
                                <input type="checkbox" id="chk" kendoCheckBox [checked]="isChecked"
                                    [indeterminate]="isIndet" (click)="onClick()" />
                                <kendo-label for="chk" class="ms-1">Select All</kendo-label>
                            </span>
                        </ng-template>

                        <ng-template kendoMultiSelectItemTemplate let-dataItem>
                            <span class=" py-1">
                                <kendo-label for="chk-{{ dataItem.id }}">
                                    <input type="checkbox" id="chk-{{ dataItem.id }}" kendoCheckBox
                                        [checked]="isItemSelected(dataItem)" />
                                </kendo-label>
                            </span>
                            <span>{{ dataItem.name }}</span>
                        </ng-template>
                    </kendo-multiselect> -->


                    <ng-select [items]="regionsList" placeholder="Select Region" bindLabel="name" bindValue="name"
                        [(ngModel)]="selectedRegions" (change)="onFilterChange($event)" [multiple]="true"
                        [deselectOnClick]="true">

                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                            <span class="ng-value-label">{{ item?.name }}</span>
                            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </ng-template>

                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                            <div class="ng-value" *ngFor="let item of items.slice(0,2)">
                                <span class="ng-value-label" *ngIf="item?.name">{{ item?.name }}</span>
                            </div>
                            <div class="ng-value" *ngIf="items.length > 2">
                                <span class="ng-value-label">+ {{ items.length - 2 }} more</span>
                            </div>
                        </ng-template>

                    </ng-select>
                </div>
            </div>




            <div class="col-lg-4">
                <div class="mb-3">
                    <label class="form-label">Projects Created on</label>
                    <div class="position-relative">
                        <input type="text" class="form-control date"
                            [value]="filterProjectBillDates ? (formatDateRange(filterProjectBillDates)) : ''"
                            placeholder="Select Date" name="daterange" autocomplete="off" bsDaterangepicker
                            [bsConfig]="datePickerConfig" [(bsValue)]="filterProjectBillDates"
                            (keydown)="preventManualInput($event)" />
                        <span class="bx bx-calendar inputIcon" (click)="openCalendar()"></span>
                        <!-- Clear icon -->
                        <span class="bx bx-x inputIcon clear-icon" *ngIf="filterProjectBillDates.length>0"
                            (click)="clearDate()"></span>
                    </div>
                </div>
            </div>




            <div class="col-lg-4 d-flex align-items-center sm-btn">
                <button class="btn btn-primary btn-md mt-2" (click)="filterProjects('filter')"> Apply
                </button>
                <button class="btn btn-outline-primary btn-md mt-2 ms-2" (click)="filterProjects('reset')"> Reset
                </button>
            </div>
        </div>

        <!-- </form> -->
    </div>
</div>

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <ng-template #template let-anchor>
                    <span>{{ anchor.nativeElement.innerText }}</span>
                </ng-template>
                <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                    (mouseover)="showTooltip($event)" [position]="'left'">
                    <kendo-grid [data]="projectsGridData" [pageSize]="pageSize" [skip]="skip" [sortable]="true"
                        [selectable]="selectableSettings" (selectionChange)="selectionChanged($event)" [pageable]="{
                           buttonCount: buttonCount,
                             info: info,
                            type: type,
                           pageSizes: pageSizes,
                          previousNext: previousNext
                        }" [reorderable]="true" filterable="menu" [filter]="projectstate.filter"
                        [sort]="projectstate.sort" [columnMenu]="{ filter: true }"
                        (filterChange)="onprojectFilterChange($event)" (sortChange)="onSortChange($event)"
                        [resizable]="true" scrollable="scrollable" (pageChange)="projectPageChange($event)"
                        (dataStateChange)="dataStateChange($event)" style="width: 100%; overflow-x: auto;">

                        <ng-template kendoGridToolbarTemplate>
                            <!-- <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..."
                                [(ngModel)]="searchedValue" (valueChange)="onFilter($event)"></kendo-textbox> -->
                            <div class="search_icon">
                                <kendo-textbox [style.width.px]="250" [clearButton]="true"
                                    (valueChange)="projectValueChange($event)" (keyup.enter)="onSearchProjects()"
                                    [style.height.px]="35" [(ngModel)]="searchedValue"
                                    placeholder="Search in all columns...">
                                </kendo-textbox>

                                <button class="btn btn-primary btn-md border-left-none" (click)="onFilter($event)">
                                    <i class="fa fa-search"></i>
                                </button>
                            </div>
                            <kendo-grid-spacer></kendo-grid-spacer>
                            @if(currentRole!='SuperUser'){<button class="btn btn-outline-primary btn-sm me-2"
                                (click)="addNewProject()">Add New
                            </button>}
                            <!-- <button kendoGridAddCommand class="btn btn-light" (click)="addNewProject()">Add New
                            </button> -->
                            <!-- <button class="btn btn-light" (click)="onExport('Pdf')">
                            <span class="fas fa-file-pdf"> </span> &nbsp; Export to PDF</button> -->



                            <button class="btn btn-outline-primary btn-sm d-flex align-items-center"
                                (click)="onExport('Excel')">
                                <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                            </button>
                        </ng-template>

                        <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
                            class="no-tooltip"></kendo-grid-checkbox-column>
                        <kendo-grid-column field="code" [hidden]="true" title="Project Code"></kendo-grid-column>
                        <kendo-grid-column field="name" title="Project Name"></kendo-grid-column>
                        <!-- <kendo-grid-column field="name" title="Product Name">
                            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                              <kendo-grid-string-filter-menu [column]="column" [filter]="filter"
                                                             [operators]="[{ text: 'Is equal to', value: 'eq' }, { text: 'Contains', value: 'contains' }]">
                              </kendo-grid-string-filter-menu>
                            </ng-template>
                          </kendo-grid-column> -->

                        <kendo-grid-column field="billingType.name" title="Bill Type"></kendo-grid-column>
                        <kendo-grid-column field="region.name" title="Region"></kendo-grid-column>

                        <!-- <kendo-grid-column field="billDate" title="Bill Date" format="{0:MM-dd-yyyy}"></kendo-grid-column> -->
                        <kendo-grid-column field="createdOn" title="Created Date" filter="date">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                {{ dataItem.createdOn | date: 'dd MMM yyyy, h:mm a' }}
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column field="billingInitiatedOn" title="Billing Initiated On" filter="date">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                {{ dataItem.billingInitiatedOn | date: 'dd MMM yyyy, h:mm a' }}
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column field="billDate" title="Bill Date">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                {{ dataItem.billDate == '' ? 'Daily' :
                                formatBillingDate(dataItem.billDate) }}
                            </ng-template>
                        </kendo-grid-column>


                        <kendo-grid-column field="projectType.name" title="Project Type"></kendo-grid-column>
                        <kendo-grid-column field="isActive" title="Project Status" [width]="150" filter="boolean">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span
                                    [ngClass]="{'text-success': dataItem.isActive, 'text-danger': !dataItem.isActive}">
                                    {{ dataItem.isActive ? 'Active' : 'Inactive' }}
                                </span>
                            </ng-template>
                        </kendo-grid-column>



                        <!-- <kendo-grid-command-column title="Actions" [columnMenu]="false" [width]="200">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div class="d-flex justify-content-between">
                                    <button tooltip="Go to Project Edit"
                                        class="btn btn-primary btn-sm d-flex align-items-center"
                                        (click)="updateProject(dataItem)">
                                        <i class="fas fa-edit"></i>
                                    </button>
                                    <button class="btn btn-success btn-sm d-flex align-items-center"
                                        tooltip="Go to Project Details" (click)="viewProject(dataItem)">
                                        <i class="fas fa-info-circle"></i>
                                    </button>
                                    <button placement="left" tooltip="Go to Project Instance"
                                        class="btn btn-light btn-sm align-items-center"
                                        (click)="goToProjectInstance(dataItem)">
                                        <i class="bx bx-cube font-size-14"></i>
                                    </button>
                                    <button class="btn btn-danger btn-sm d-flex align-items-center p-2"
                                    tooltip="Delete Project" (click)="deleteProject(dataItem)">
                                    <i class="fas fa-trash-alt"></i>
                                </button>
                                </div>
                            </ng-template>
                        </kendo-grid-command-column> -->


                        <kendo-grid-command-column title="" [columnMenu]="false" [width]="90">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div class="d-flex">
                                    <!-- Edit Project Icon -->
                                    <span placement="left" tooltip="Go to Project Edit" class="me-3"
                                        (click)="updateProject(dataItem)">
                                        <i class="fas fa-edit text-primary" style="cursor: pointer;"></i>
                                    </span>

                                    <!-- Go to Project Instance Icon -->
                                    <span placement="left" tooltip="Go to Project Instance" class="ms-2"
                                        (click)="goToProjectInstance(dataItem)">
                                        <!-- <i class="bx bx-cube font-size-14 text-dark" style="cursor: pointer;"></i> -->
                                        <img src="../../../assets/images/change.svg" style="cursor: pointer;" alt=""
                                            height="18">
                                    </span>
                                </div>
                            </ng-template>
                        </kendo-grid-command-column>

                    </kendo-grid>

                    <!-- </div> -->

                    <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                        <kendo-grid
                            [kendoGridBinding]="mySelection.length>0 ? mySelection:allProjectsData.length>0 ? allProjectsData:projectsGridData.data"
                            #pdfGrid>
                            <kendo-grid-column field="code" title="Project Code"></kendo-grid-column>
                            <kendo-grid-column field="name" title="Project Name"></kendo-grid-column>
                            <kendo-grid-column field="billingType.name" title="Bill Type"></kendo-grid-column>
                            <kendo-grid-column field="region.name" title="Region"></kendo-grid-column>
                            <!-- <kendo-grid-column field="" title="Utility"></kendo-grid-column> -->
                            <kendo-grid-column field="createdOnDate" title="Created Date" [width]="150">

                            </kendo-grid-column>
                            <kendo-grid-column field="billingInitiatedOn" title="Billing Initiated On"
                                filter="date"></kendo-grid-column>
                            <!-- <kendo-grid-column field="createdOn" title="Created Date">
                            <ng-template kendoGridCellTemplate let-dataItem>
                              {{ dataItem.createdOn | date: 'dd MMM yyyy, h:mm a' }}
                            </ng-template>
                          </kendo-grid-column> -->



                            <kendo-grid-column field="billDate" title="Bill Date"
                                format="{0:MM-dd-yyyy}"></kendo-grid-column>


                            <kendo-grid-column field="projectType.name" title="Project Type"></kendo-grid-column>
                            <kendo-grid-column field="status" title="Project Status"></kendo-grid-column>
                            <kendo-grid-pdf fileName="Projects.pdf" [allPages]="true" paperSize="A4"
                                [landscape]="true"></kendo-grid-pdf>
                            <kendo-grid-excel fileName="Projects.xlsx"></kendo-grid-excel>
                        </kendo-grid>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>