import { LoaderService } from '../../../../core/services/loader.service';
import { Component, Input, SimpleChanges } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { SharedService } from '../../../../shared/shared.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  createMaster,
  updateMaster,
} from '../../../../store/Masters/masters.actions';
import { Helper } from '../../../../core/helpers/helper';
import { Validations } from '../../../../shared/constants/validations';
import { Monitoring } from '../../../../store/Monitoring/monitoring.models';
import {
  createMonitoring,
  updateMonitoring,
} from '../../../../store/Monitoring/monitoring.actions';
import { error } from 'node:console';

@Component({
  selector: 'app-rp-monitoring',
  templateUrl: './rp-Create-Monitoring.component.html',
  styleUrl: './rp-Create-Monitoring.component.scss',
})
export class RpCreateMonitoringComponent {
  @Input() monitoringData!: any;  
  createMonitoringForm!: UntypedFormGroup;
  submitted: boolean = false;
  Monitoring_Validations = Validations.MonitoringCreatePage;
  @Input() projectId: any;
  @Input() configData:any;
  title: string = 'Create Monitoring';
  constructor(
    private router: Router,
    private store: Store,
    private modalService: BsModalService,
    private loaderService: LoaderService,
    private sharedService: SharedService,
    private formBuilder: UntypedFormBuilder
  ) {
    // this.getAllConfigData();
  }
  ngAfterViewInit() {
      console.log('ngOnChanges - Config Data:', this.configData);
      const defaultUtilityId = this.configData?.utilities.find((utility:any) => utility.name === 'Electricity')?.id || null;

      console.log('sssssssssssssss',defaultUtilityId)
      this.createMonitoringForm.patchValue({
        meterNumber: this.monitoringData?.meterNumber
          ? this.monitoringData.meterNumber
          : '',
          macAddress:this.monitoringData?.macAddress
          ? this.monitoringData.macAddress
          : '',
        utilityId: this.monitoringData?.utilityId
          ? this.monitoringData.utilityId
          : defaultUtilityId,
      });
    
      if (this.monitoringData?.meterNumber.length > 0) {
        this.title = 'Update Monitoring Device';
      } else {
        this.title = 'Create Monitoring';
      }
  }
  dismissModal() {
    this.modalService.hide();
  }

  CreateMonitoring(type: string) {
    this.submitted = true;
    // let projectId = '8d278d1e-7629-4790-cff6-08dc798b288a';
    if (this.createMonitoringForm.valid) {
      this.loaderService.setLoading(true);
      let payload = {
        meterNumber: this.createMonitoringForm.get('meterNumber')?.value,
        macAddress:this.createMonitoringForm.get('macAddress')?.value,
        projectId: this.projectId,
        utilityId: this.createMonitoringForm.get('utilityId')?.value,
      };
      // Process form data (send to backend, etc.)
      console.log(this.createMonitoringForm.value);
      if (type == 'Create') {
        this.store.dispatch(createMonitoring({ MonitoringData: payload }));
      } else if (type == 'Update') {
        let payload = {
          meterNumber: this.createMonitoringForm.get('meterNumber')?.value,
          macAddress:this.createMonitoringForm.get('macAddress')?.value,
          utilityId: this.createMonitoringForm.get('utilityId')?.value,
        };
        console.log(this.createMonitoringForm.value);
        this.store.dispatch(
          updateMonitoring({
            MonitoringData: payload,
            id: this.monitoringData?.id ? this.monitoringData?.id : '',
          })
        );
      }
      this.modalService.hide();
    }
  }

  ngOnInit(): void {
    this.createMonitoringForm = this.formBuilder.group({
      meterNumber: [
        '',
        [
          Validators.required,
          // Validators.minLength(2),
          // Validators.maxLength(40),
          // Helper.PatternValidator(/^[a-zA-Z0-9\s]*$/),
        ],
      ],
      macAddress: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(40),
          Helper.PatternValidator(/^[a-zA-Z0-9\s]*$/),
        ],
      ],
      utilityId: [{ value: null, disabled: true }, Validators.required],
    });

  }

  // getAllConfigData(): void {
  //   console.log('monitioring',this.monitoringData);
  //   this.sharedService.getAllConfigData().subscribe((data) => {
  //     if (
  //       data.statusCode == 200 &&
  //       data.message == 'Config data retrieved successfully.'
  //     ) {
  //       this.configData = data;
  //       console.log('monitoring2',this.monitoringData);
  //       const defaultUtilityId = this.configData?.data?.utilities.find((utility:any) => utility.name === 'Electricity')?.id || null;
  //       this.createMonitoringForm.patchValue({
  //         meterNumber: this.monitoringData?.meterNumber
  //           ? this.monitoringData.meterNumber
  //           : '',
  //         utilityId: this.monitoringData?.utilityId
  //           ? this.monitoringData.utilityId
  //           : defaultUtilityId,
  //       });
      
  //       if (this.monitoringData?.meterNumber.length > 0) {
  //         this.title = 'Update Monitoring Device';
  //       } else {
  //         this.title = 'Create Monitoring';
  //       }
  //       //
  //     }
  //     console.log('Types', this.configData.data.types);
  //   });
  // }
}
