import { createAction, props } from '@ngrx/store';
import { Master } from './projects.models';
import { ProjectData } from '../../shared/modals/projectModal';

// Masters creation
export const GetProjects = createAction(
  '[Projects] getProjects',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    Region?: any;
    FromDate?: any;
    ToDate?: any;
    filter?: string;
    orderBy?: string;
  }>()
);

export const GetallProjects = createAction(
  '[Projects] getallProjects',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    Region?: any;
    FromDate?: any;
    ToDate?: any;
    filter?: string;
    orderBy?: string;
    criteria?: string;
  }>()
);
export const getAllProjectsSuccess = createAction(
  '[Projects] GetAllProjects Success',
  props<{ getAllProjectsResponse: any }>()
);
export const getAllProjectsFailure = createAction(
  '[Projects] GetAllProjects Failure',
  props<{ getAllProjectsError: any }>()
);

export const getProjectsSuccess = createAction(
  '[Projects] GetProjects Success',
  props<{ getProjectsResponse: any }>()
);
export const getProjectsFailure = createAction(
  '[Projects] GetProjects Failure',
  props<{ getProjectsError: any }>()
);





// get projectData
export const getProject = createAction(
  '[Projects] getProject',
  props<{ projectId: any }>()
);
export const getProjectDataSuccess = createAction(
  '[Projects] getProjectDataSuccess Success',
  props<{ getProjectDataSuccessResponse: any }>()
);
export const getProjectFailure = createAction(
  '[Projects] getProjectFailure',
  props<{ getProjectFailure: any }>()
);



// Create master
export const createProject = createAction(
  '[Projects] createProject',
  props<{ projectData: ProjectData }>()
);
export const createProjectSuccess = createAction(
  '[Projects] createProject Success',
  props<{ createProjectResponse: any }>()
);
export const createProjectFailure = createAction(
  '[Projects] createProject Failure',
  props<{ createProjectError: any }>()
);

export const resetUpdateProjectResponse = createAction(
  '[Project] Reset Update Project Response'
);

// Update master
export const updateProject = createAction(
  '[Projects] updateProject',
  props<{ projectData: ProjectData; id: string }>()
);
export const updateProjectSuccess = createAction(
  '[Projects] updateProject Success',
  props<{ updateProjectResponse: any }>()
);
export const updateProjectFailure = createAction(
  '[Projects] updateProject Failure',
  props<{ updateProjectError: any }>()
);

//delete master
export const deleteProject = createAction(
  '[Projects] deleteProject',
  props<{ id: string }>()
);
export const deleteProjectSuccess = createAction(
  '[Projects] deleteProject Success',
  props<{ deleteProjectResponse: any }>()
);
export const deleteProjectFailure = createAction(
  '[Projects] deleteProject Failure',
  props<{ deleteProjectError: any }>()
);

export const resetProjectState = createAction('[Projects ] Reset State');
export const resetgetProjectState = createAction('[Projects ] Reset Project State');
