import { getProject, resetProjectState } from './../../../store/Projects/projects.actions';
import { projectBillingDates, projectDueDates } from './../../../shared/constants/projectBillingDates';
import { Component, ChangeDetectorRef, OnInit, ViewChild } from '@angular/core';
// import {DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
PdfexportService;
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';

import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { SharedService } from './../../../shared/shared.service';
import { LoaderService } from './../../../core/services/loader.service';
import { Store, select } from '@ngrx/store';
import {
  createProject,
  updateProject,
} from '../../../store/Projects/projects.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from '../../../core/helpers/helper';
import { Validations } from '../../../shared/constants/validations';
import {
  SelectableSettings,
  SelectableMode,

} from '@progress/kendo-angular-grid';
import { RemoveEvent } from '@progress/kendo-angular-upload';
import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
} from '@progress/kendo-angular-grid';
import { SVGIcon, filePdfIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
import { process, State } from '@progress/kendo-data-query';
import {
  orderBy,
  SortDescriptor,
  filterBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import {
  deleteGateWay,
  getGatewayExport,
  getGatewayList,
  SetGatewayStatus, resetGateWaysState,
} from '../../../store/GateWay/gateway.action';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RpCreateGatewayComponent } from '../project-details/rp-create-gateway/rp-create-gateway.component';
import { BillingType, SweetAlertIcon, UtilityType } from '../../../shared/constants/enum';
import {
  deleteGateWayResponse,
  getGateWayResponse,
  creategatewayResponse,
  updateGateWayResponse,
  getGatewayExportResponse,
} from '../../../store/GateWay/gateway-selector';
import { table_pageSize } from '../../../shared/constants/templatesNames';
import { PdfexportService } from '../../../core/services/pdfexport.service';
import { getProjectDataSuccessResponse, getProjectsResponse, updateProjectResponse } from '../../../store/Projects/projects-selector';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { CanComponentDeactivate } from '../../../core/guards/can-deactivate.guard';
import { formatDate } from '@angular/common';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
@Component({
  selector: 'app-rp-create-project',
  templateUrl: './rp-create-project.component.html',
  styleUrl: './rp-create-project.component.scss',
})
export class RpCreateProjectComponent implements CanComponentDeactivate {
  projectForm!: UntypedFormGroup;
  // file upload
  public selectableSettings!: SelectableSettings;
  public pageSizes = table_pageSize.pageSizes;
  public filePdfIcon: SVGIcon = filePdfIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;
  uploadSaveUrl = 'saveUrl'; // should represent an actual API endpoint
  uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  // public dropzoneConfig: DropzoneConfigInterface = {
  //   clickable: true,
  //   addRemoveLinks: true,
  //   previewsContainer: false
  // };
  @ViewChild('pdfGrid') public hiddenGrid: GridComponent | undefined;
  public gatewaygridData: GridDataResult = { data: [], total: 0 };
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public sort: SortDescriptor[] = [];
  totalProjectCount: number = 0;
  currentPage: number = 1;
  gatewayList: any[] = [];
  selectValue!: string[];

  projectBillingDates = projectBillingDates;
  projectDueDates = projectDueDates;
  files: File[] = [];
  uploadedFiles: any[] = [];

  configData: any;
  cities: any = [];
  submitted: boolean = false;
  projectDetails: any;
  isprojectEdit: boolean = false
  projectValidations = Validations.Project_ValidationMessages;
  requiredprojectBilling: any;

  public pageSize = 10;
  public skip = 0;
  projectId: any;
  orderByQueryOfGatewat: string = '';
  gateWayQueryString: string = '';
  gateWaySearch: string = '';
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  typeOf: string = '';
  public previousNext = true;
  //public pageSizes = true;
  modalRef!: BsModalRef;
  UtilityType = UtilityType;
  config: any = {
    backdrop: true,
    ignoreBackdropClick: true,
  };

  billingInitiatedStatus = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ];

  public gateWayState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  today: Date;
  selectedDate: any
  selectedRowdata: any = [];
  deselectedRowdata: any = [];
  totalGateWayCount: number = 0;
  breadCrumbItems: Array<{}> | undefined;
  isDisplayFieldsBasedOnBillType: boolean = false;
  displayfieldsBasedonWalletCheckBox: boolean = false;
  datePickerConfig: Partial<BsDatepickerConfig>;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private loaderService: LoaderService,
    private store: Store,
    private route: ActivatedRoute,
    public sharedService: SharedService,
    private modalService: BsModalService,
    private PdfexportService: PdfexportService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {

    this.datePickerConfig = Object.assign({}, {
      dateInputFormat: 'DD/MM/YYYY', // Set to dd/MM/yyyy format
      showWeekNumbers: false,
      isAnimated: true,
      containerClass: 'theme-dark-blue'
    });



    this.today = new Date()
    // this.getAllConfigData();
    this.configData = history.state.configData;
    this.configData.waterProviders = this.configData.suppliers.filter(
      (data: any) => data.master.name.trim().toLowerCase() === "water"
    );
    this.configData.gasProviders = this.configData.suppliers.filter(
      (data: any) => data.master.name.trim().toLowerCase() === "gas"
    );
    this.configData.electricityProviders = this.configData.suppliers.filter(
      (data: any) => data.master.name.trim().toLowerCase() === "electricity"
    );

    this.configData.TriSourceProviders = this.configData.suppliers.filter(
      (data: any) => {
        console.log(data.master.name);  // Check value of `name` before filtering
        return data.master.name.trim().toLowerCase() === "trisource";  // Case insensitive comparison
      }
    );

    console.log(history.state.projectDetails);
    this.typeOf = history.state.type;
    this.projectId = history.state?.projectDetails
      ? JSON.parse(history.state.projectDetails)?.id
      : null;
    if (this.projectId) {
      this.getStoreInfo();
      this.breadCrumbItems = [
        { label: 'Project', url: 'projects', active: true },
        { label: 'Edit projects' },
      ];
    } else {
      this.breadCrumbItems = [
        { label: 'Project', url: 'projects', active: true },
        { label: 'Create projects' },
      ];
    }
  }


  // canDeactivate(): boolean {
  //   if (this.projectForm.dirty && this.projectId == null) {
  //     //this.confirmMode()
  //     return window.confirm(`'You're about to leave the project creation process.If you continue, all unsaved data will be lost.Do you wish you proceed ? '`);
  //   }
  //   return true;
  // }

  canDeactivate(): any {
    // Check if form is dirty and projectId is null
    if (this.projectForm.dirty && this.projectId == null) {
      // Return a Promise to wait for the user response
      return new Promise((resolve) => {
        this.sharedService.showConfirmMessageDialog(
          'Are you sure?', // Title
          "You're about to leave the project creation process. If you continue, all unsaved data will be lost. Do you wish to proceed?", // Message
          SweetAlertIcon.WARNING // Icon type (can be 'warning', 'error', 'success', etc.)
        ).then((result) => {
          // Resolve the promise based on user action
          if (result.isConfirmed) {
            resolve(true); // Proceed with navigation
          } else {
            resolve(false); // Stay on the page
          }
        });
      });
    }
    return Promise.resolve(true); // No confirmation needed, allow navigation
  }

  // onDateRangeSelected(event: any): void {
  //   console.log('Selected date:', event);
  //   this.selectedDate = event.toISOString();

  //   // Handle the selected date here
  // }

  // onDateRangeSelected(event: Date | null): void {
  //   if (event) {
  //     this.selectedDate = event.toISOString();
  //     this.projectForm.controls['StartDate'].setValue(this.selectedDate);
  //   } else {
  //     this.selectedDate = null;
  //     this.projectForm.controls['StartDate'].setValue(null); // Set form control to null
  //   }
  // }

  // onDateRangeSelected(event: Date | null): void {
  //   console.log('222222222222222222222222',event);

  //   if (event) {
  //     const adjustedStartDate = new Date(event.getTime() - event.getTimezoneOffset() * 60000);
  //     this.selectedDate = adjustedStartDate.toISOString();
  //     this.projectForm.controls['StartDate'].setValue(this.selectedDate);
  //   } else {
  //     this.selectedDate = null;
  //     this.projectForm.controls['StartDate'].setValue(null); // Set form control to null
  //   }
  // }

  convertToUTC(date: Date): string {
    const adjustedStartDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    let selectedDate = adjustedStartDate.toISOString();

    // const utcDate = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
    console.log(selectedDate)
    return selectedDate; // Return the UTC date in ISO format: '2025-01-15T00:00:00.000Z'
  }

  public onRemove(event: RemoveEvent): void {
    // console.log('File removed:', event.files);

    if (this.projectDetails) {
      this.projectDetails.logoUrl = '';
    }

    // You can add additional logic here, such as notifying the server or updating your UI state
  }

  openCalendar() {
    // Trigger the click event on the input field to open the datepicker
    const dateInput = document.querySelector('.form-control.date');
    dateInput?.dispatchEvent(new Event('click'));
  }
  clearDate() {
    this.selectedDate = null; // Clear the selected date
    this.projectForm.controls['StartDate'].setValue(null);
  }
  ngOnInit(): void {

    this.initializeForm();

    this.projectForm.get('billingType')?.valueChanges.subscribe((value) => {
      this.onBillingTypeChange(value);
    });

    this.projectForm.get('state')?.valueChanges.subscribe((selectedStateId) => {
      this.updateCitiesBasedOnState(selectedStateId);
    });
    this.projectForm.controls['IsBillingInitiatedDate'].disable();
    this.projectForm
      .get('IsWalletEnabled')
      ?.valueChanges.subscribe((wallet) => {
        this.updateWalletFields(wallet);
      });

    this.projectForm
      .get('SelectedUtilityIds')
      ?.valueChanges.subscribe((selectedUtilities) => {
        // Reset validators
        this.projectForm.get('valveCutofflimit')?.clearValidators();
        // this.projectForm.get('valveCutofflimit')?.reset();
        this.projectForm.get('relayCutofflimit')?.clearValidators();
        // this.projectForm.get('relayCutofflimit')?.reset();
        this.projectForm.get('gasProvider')?.clearValidators();
        // this.projectForm.get('gasProvider')?.reset();
        this.projectForm.get('electricityProvider')?.clearValidators();
        // this.projectForm.get('electricityProvider')?.reset();
        this.projectForm.get('waterProvider')?.clearValidators();
        // this.projectForm.get('waterProvider')?.reset();
        this.projectForm.get('triSourceProvider')?.clearValidators();

        this.projectForm.get('gasConsumptionLimit')?.clearValidators();
        this.projectForm.get('waterConsumptionLimit')?.clearValidators();
        this.projectForm.get('electricityEBConsumptionLimit')?.clearValidators();
        this.projectForm.get('electricityDGConsumptionLimit')?.clearValidators();



        const selectedBillingTypeName = this.configData?.billingTypes.find(
          (billingType: any) => billingType.id === this.projectForm.get('billingType')?.value
        )?.name;

        const isBillingTypePrepaid = selectedBillingTypeName === BillingType.PREPAID;


        if (selectedUtilities) {
          if (
            this.configData?.utilities.some(
              (utility: any) =>
                selectedUtilities.includes(utility.id) &&
                utility.name === 'Electricity'
            )
          ) {
            if (isBillingTypePrepaid) {
              this.projectForm
                .get('relayCutofflimit')
                ?.setValidators([
                  Validators.required,
                  Helper.numberWithDecimalsValidator(),
                ]);
            }
            this.projectForm
              .get('electricityProvider')
              ?.setValidators([Validators.required]);
            this.projectForm.get('electricityEBConsumptionLimit')?.setValidators([Validators.required]);
            this.projectForm.get('electricityDGConsumptionLimit')?.setValidators([Validators.required]);
          }
          if (
            this.configData?.utilities.some(
              (utility: any) =>
                selectedUtilities.includes(utility.id) && utility.name === 'Gas'
            )
          ) {
            if (isBillingTypePrepaid) {
              this.projectForm
                .get('valveCutofflimit')
                ?.setValidators([
                  Validators.required,
                  Helper.numberWithDecimalsValidator(),
                ]);
              this.projectForm.get('gasConsumptionLimit')?.setValidators([Validators.required]);
            }
            this.projectForm
              .get('gasProvider')
              ?.setValidators([Validators.required]);
          }
          if (
            this.configData?.utilities.some(
              (utility: any) =>
                selectedUtilities.includes(utility.id) &&
                utility.name === 'Water'
            )
          ) {
            this.projectForm
              .get('waterProvider')
              ?.setValidators([Validators.required]);
            this.projectForm.get('waterConsumptionLimit')?.setValidators([Validators.required]);
          }
          if (
            this.configData?.utilities.some(
              (utility: any) =>
                selectedUtilities.includes(utility.id) &&
                utility.name === 'TriSource'
            )
          ) {
            this.projectForm
              .get('triSourceProvider')
              ?.setValidators([Validators.required]);
            // this.projectForm.get('waterConsumptionLimit')?.setValidators([Validators.required]);
          }
        }

        //Update the validity status of the form controls
        this.projectForm.get('valveCutofflimit')?.updateValueAndValidity();
        this.projectForm.get('relayCutofflimit')?.updateValueAndValidity();
        this.projectForm.get('gasProvider')?.updateValueAndValidity();
        this.projectForm.get('electricityProvider')?.updateValueAndValidity();
        this.projectForm.get('waterProvider')?.updateValueAndValidity();
        this.projectForm.get('triSourceProvider')?.updateValueAndValidity();


        this.projectForm.get('gasConsumptionLimit')?.updateValueAndValidity();
        this.projectForm.get('waterConsumptionLimit')?.updateValueAndValidity();
        this.projectForm.get('electricityEBConsumptionLimit')?.updateValueAndValidity();
        this.projectForm.get('electricityDGConsumptionLimit')?.updateValueAndValidity();
      });

    this.projectForm
      .get('requireIndependentDatabase')
      ?.valueChanges.subscribe((value: boolean) => {
        this.toggleDatabaseFields(value);
      });

    if (history.state.projectDetails) {
      this.projectDetails = JSON.parse(history.state.projectDetails);
      if (this.projectDetails.id) {
        this.isprojectEdit = true; // hide daatBase tab for edit scenario
        if (!this.isprojectEdit) {
          this.projectForm.controls['Status'].disable();

          this.projectForm.controls['billingType'].enable();

          this.projectForm.controls['BillGenerationTypeId'].enable();
        } else {
          this.projectForm.controls['Status'].enable();

          this.projectForm.controls['billingType'].disable();

          this.projectForm.controls['BillGenerationTypeId'].disable();
        }
      }
      console.log(this.projectDetails);
      if (this.projectDetails.isBillingInitiated == true) {
        this.projectForm.controls['IsBillingInitiated'].disable();
      }

      this.patchFormValues();
    } else {
      // this.projectForm.patchValue({
      //   SelectedUtilityIds: this.configData?.utilities.map(
      //     (item: any) => item.id
      //   ),
      // });
    }

    if (this.typeOf === 'Edit') {
      this.getAllGatewatList();
    }

    this.selectableSettings = this.sharedService.selectableSettings;

    this.cdr.detectChanges(); // Trigger change detection if necessary
  }

  private initializeForm(): void {
    this.projectForm = this.formBuilder.group({
      project_Name: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
          Helper.PatternValidator(/^[a-zA-Z0-9]+(?:[ _&][a-zA-Z0-9]+)*$/),
        ],
      ],
      project_Code: ['', [Helper.PatternValidator(/^[a-zA-Z0-9]*$/)]],
      projectType: [null, Validators.required],
      SelectedUtilityIds: [null, Validators.required],
      region: [null, Validators.required],
      address: ['', [Validators.required, Helper.PatternValidator(/^(?!.*  ).*$/)]],
      area: [
        '',
        [Validators.required, Helper.PatternValidator(/^[a-zA-Z]+( [a-zA-Z]+)*$/)],
      ],
      city: [null, Validators.required],
      state: [null, Validators.required],
      pincode: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(6),
          Helper.PatternValidator(/^[0-9]*$/),
        ],
      ],
      LogoFile: [''],
      contactNumber: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Helper.PatternValidator(/^[0-9]*$/),
        ],
      ],
      Email: [],
      society_name: [
        '',
        [

          Validators.minLength(2),
          Validators.maxLength(20),
          Helper.PatternValidator(/^[a-zA-Z\s]*$/),
        ],
      ],
      merchantId: [
        '',
        [Helper.PatternValidator(/^[a-zA-Z0-9_]*$/)],
      ],
      GSTTINO: [
        '',
        [Helper.PatternValidator(/^[a-zA-Z0-9_]*$/)],
      ],
      accountName: [
        '',
        [Helper.PatternValidator(/^[a-zA-Z\s]*$/)],
      ],
      accountNumber: [
        '',
        [Helper.PatternValidator(/^[0-9]*$/)],
      ],
      bankName: [
        '',
        [Helper.PatternValidator(/^[a-zA-Z\s]*$/)],
      ],
      branchName: [
        '',
        [Helper.PatternValidator(/^[a-zA-Z\s]*$/)],
      ],
      ifscCode: [
        '',
        [Helper.PatternValidator(/^[a-zA-Z0-9]*$/)],
      ],
      StartDate: [null, Validators.required],
      DueDate: [null, Validators.required],
      account_address: [''],
      cancelled_Cheque_file: [''],
      Status: [{ value: 'Inactive', disabled: this.isprojectEdit }, Validators.required],
      IsBillingInitiated: [
        { value: false, },
        Validators.required
      ],
      IsBillingInitiatedDate: [],
      billingType: [null, Validators.required],
      IsWalletEnabled: [{ value: false, disabled: false }],
      BillGenerationTypeId: [null, Validators.required],
      minRechargeAmount: ['', [Helper.numberWithDecimalsValidator()]],
      minWalletBalance: ['', [Helper.numberWithDecimalsValidator()]],
      valveCutofflimit: ['', [Helper.numberWithDecimalsValidator()]],
      relayCutofflimit: ['', [Helper.numberWithDecimalsValidator()]],
      maxRechargeAmount: ['', [Helper.numberWithDecimalsValidator()]],
      billDate: [, Validators.required],
      ValveCutOffDate: [, Validators.required],
      RelayCutOffDate: [, Validators.required],
      requireIndependentDatabase: [false],
      db_password: [{ value: '', disabled: true }, Validators.required],
      db_serverName: [
        { value: '', disabled: true },
        [Validators.required, Helper.PatternValidator(/^[a-zA-Z0-9]*$/)],
      ],
      db_userName: [
        { value: '', disabled: true },
        [Validators.required, Helper.PatternValidator(/^[a-zA-Z0-9]*$/)],
      ],
      gasProvider: [null, Validators.required],
      electricityProvider: [null, Validators.required],
      waterProvider: [null, Validators.required],
      triSourceProvider: [null, Validators.required],
      bluetoothDeviceIncluded: [false],
      meterMenu: [false],
      emailNotifications: [false],
      smsNotifications: [false],
      IsDisplayOldBills: [false],
      meterOnOff: [false],
      IsBillGenerateForEmptyResidentialUnit: [false],
      isGIReadable: [true, Validators.required],
      waterConsumptionLimit: ['', [Helper.numberWithDecimalsValidator(), Validators.required]],
      gasConsumptionLimit: ['', [Helper.numberWithDecimalsValidator(), Validators.required]],
      electricityDGConsumptionLimit: ['', [Helper.numberWithDecimalsValidator(), Validators.required]],
      electricityEBConsumptionLimit: ['', [Helper.numberWithDecimalsValidator(), Validators.required]],
    });

    if (!this.isprojectEdit) {
      this.projectForm.controls['Status'].disable();
    }
  }

  private updateCitiesBasedOnState(selectedStateId: any): void {
    const selectedState = this.configData.states.find(
      (state: any) => state.id === selectedStateId
    );
    this.cities = selectedState ? selectedState.cities : [];
    // this.projectForm.get('city')?.setValue(null);
  }

  selectionChange(event: any) {
    console.log(event)
    if (event == 'Active') {
      this.projectForm.controls['merchantId'].setValidators(Validators.required);
      this.projectForm.controls['merchantId'].updateValueAndValidity();
    } else {
      this.projectForm.controls['merchantId'].clearValidators();
      this.projectForm.controls['merchantId'].updateValueAndValidity();
    }
  }

  private toggleDatabaseFields(value: boolean): void {
    const dbPasswordControl = this.projectForm.get('db_password');
    const db_serverName = this.projectForm.get('db_serverName');
    const db_userName = this.projectForm.get('db_userName');
    if (value) {
      dbPasswordControl?.enable();
      db_serverName?.enable();
      db_userName?.enable();
    } else {
      dbPasswordControl?.disable();
      db_serverName?.disable();
      db_userName?.disable();
      this.projectForm.patchValue({
        db_password: '',
        db_serverName: '',
        db_userName: '',
      });
    }
  }


  private patchFormValues(): void {
    console.log(this.projectDetails)
    this.selectedBillType = this.projectDetails?.billingType;
    let logo_url = this.projectDetails?.logoUrl
      ? [
        {
          name: this.projectDetails.logoFileName,
          src: this.projectDetails.logoUrl,
          size: this.projectDetails.logoFileSize,
          uid: 1,
        },
      ]
      : '';
    let chequFile_url = this.projectDetails?.societyHead?.fileUrl
      ? [
        {
          name: this.projectDetails?.societyHead?.fileName,
          src: this.projectDetails.societyHead.fileUrl,
          size: this.projectDetails?.societyHead?.fileSize,
          uid: 1,
        },
      ]
      : '';

    const selectedDueDate = this.projectDetails?.dueDate
      ? Number(this.projectDetails.dueDate)
      : null;

    this.projectForm.patchValue({
      project_Name: this.projectDetails.name,
      project_Code: this.projectDetails.code,
      projectType: this.projectDetails.projectType?.id,
      region: this.projectDetails.region?.id,
      address: this.projectDetails.address,
      area: this.projectDetails.area,
      state: this.projectDetails.state?.id,
      city: this.projectDetails.city?.id,
      Status: this.projectDetails.status,
      IsBillingInitiated: this.projectDetails?.isBillingInitiated,
      // IsBillingInitiatedOn: this.projectDetails?.billingInitiatedOn,
      pincode: this.projectDetails.pinCode,
      StartDate: this.projectDetails?.startDate && this.projectDetails?.startDate !== '0001-01-01T00:00:00'
        ? new Date(this.projectDetails.startDate)
        : null,
      IsBillingInitiatedDate: this.projectDetails?.billingInitiatedOn && this.projectDetails?.billingInitiatedOn !== '0001-01-01T00:00:00'
        ? new Date(this.projectDetails.billingInitiatedOn)
        : null,

      DueDate: selectedDueDate,
      SelectedUtilityIds: this.projectDetails.selectedUtilities.map(
        (item: any) => item.id
      ),
      LogoFile: logo_url,
      contactNumber: this.projectDetails.contactNumber,
      Email: this.projectDetails.email,
      relayCutofflimit: this.projectDetails.relayCutofflimit,
      valveCutofflimit: this.projectDetails.valveCutofflimit,
      society_name: this.projectDetails.societyHead?.name,
      merchantId: this.projectDetails.societyHead?.merchantId,
      GSTTINO: this.projectDetails.gsttino,
      accountName: this.projectDetails.societyHead?.accountName,
      accountNumber: this.projectDetails.societyHead?.accountNumber,
      bankName: this.projectDetails.societyHead?.bankName,
      branchName: this.projectDetails.societyHead?.branchName,
      ifscCode: this.projectDetails.societyHead?.ifscCode,
      account_address: this.projectDetails.address,
      cancelled_Cheque_file: chequFile_url,
      BillGenerationTypeId: this.projectDetails.billGenerationType?.id,
      billingType: this.projectDetails.billingType?.id,
      minRechargeAmount: this.projectDetails.minRechargeAmount,
      minWalletBalance: this.projectDetails.minWalletBalance,
      maxRechargeAmount: this.projectDetails.maxRechargeAmount,
      billDate: this.getOrdinalSuffix(this.projectDetails.billDate),
      ValveCutOffDate: this.projectDetails.valveCutOffDate,
      RelayCutOffDate: this.projectDetails.relayCutOffDate,
      gasProvider: this.projectDetails?.gasProviders?.map(
        (item: any) => item.id
      ),
      electricityProvider: this.projectDetails?.electricityProviders?.map(
        (item: any) => item.id
      ),
      waterProvider: this.projectDetails?.waterProviders?.map(
        (item: any) => item.id
      ),
      triSourceProvider: this.projectDetails?.triSourceProviders?.map(
        (item: any) => item.id
      ),
      bluetoothDeviceIncluded: this.projectDetails.bluetoothDeviceIncluded,
      meterMenu: this.projectDetails.meterMenu,
      emailNotifications: this.projectDetails.emailNotifications,
      smsNotifications: this.projectDetails.smsNotifications,
      IsDisplayOldBills: this.projectDetails.isDisplayOldBills,
      IsBillGenerateForEmptyResidentialUnit:
        this.projectDetails.isBillGenerateForEmptyResidentialUnit,
      isGIReadable: this.projectDetails.isGIReadable,
      IsWalletEnabled: this.projectDetails.isWalletEnabled,
      meterOnOff: this.projectDetails?.isMeterOnOff,
      waterConsumptionLimit: this.projectDetails?.waterConsumptionLimit,
      gasConsumptionLimit: this.projectDetails?.gasConsumptionLimit,
      electricityDGConsumptionLimit: this.projectDetails?.electricityDGConsumptionLimit,
      electricityEBConsumptionLimit: this.projectDetails?.electricityEBConsumptionLimit,

    });

    this.updateCitiesBasedOnState(this.projectDetails.state?.id);
  }
  selectedBillType: any
  billingTypeChange(event: any) {
    this.selectedBillType = event
    console.log(event)

  }

  getOrdinalSuffix(day: any) {

    if (day === '2 days before calendar' || day === '1 day before calendar' || day === 'Last day of the month') {
      return day;
    } else {
      let cleanedString = day.replace(/(\d+)(st|nd|rd|th) of every month/, "$1");
      //let cleanedString = day.replace(" th of every month", "");
      return cleanedString
    }
  }

  isDisplay(key: string): boolean {
    const selectedUtilities =
      this.projectForm.get('SelectedUtilityIds')?.value || [];
    return selectedUtilities.some((utility: any) => {
      const matchingUtility = this.configData?.utilities.find(
        (u: any) => u.id === utility
      );
      return matchingUtility?.name === key;
    });
  }

  get f() {
    return this.projectForm.controls;
  }
  onUtilityChange(): void {
    this.projectForm
      .get('SelectedUtilityIds')
      ?.valueChanges.subscribe((selectedUtilities) => {
        // Reset validators

        this.projectForm.get('valveCutofflimit')?.clearValidators();
        this.projectForm.get('valveCutofflimit')?.reset();
        this.projectForm.get('relayCutofflimit')?.clearValidators();
        this.projectForm.get('relayCutofflimit')?.reset();
        this.projectForm.get('gasProvider')?.clearValidators();
        this.projectForm.get('gasProvider')?.reset();
        this.projectForm.get('electricityProvider')?.clearValidators();
        this.projectForm.get('electricityProvider')?.reset();
        this.projectForm.get('waterProvider')?.clearValidators();
        this.projectForm.get('waterProvider')?.reset();

        this.projectForm.get('triSourceProvider')?.clearValidators();
        this.projectForm.get('triSourceProvider')?.reset();

        if (selectedUtilities) {
          if (
            this.configData?.utilities.some(
              (utility: any) =>
                selectedUtilities.includes(utility.id) &&
                utility.name === 'Electricity'
            )
          ) {
            this.projectForm
              .get('relayCutofflimit')
              ?.setValidators([
                Validators.required,
                Helper.numberWithDecimalsValidator(),
              ]);
            this.projectForm
              .get('electricityProvider')
              ?.setValidators([Validators.required]);
          }
          if (
            this.configData?.utilities.some(
              (utility: any) =>
                selectedUtilities.includes(utility.id) && utility.name === 'Gas'
            )
          ) {
            this.projectForm
              .get('valveCutofflimit')
              ?.setValidators([
                Validators.required,
                Helper.numberWithDecimalsValidator(),
              ]);
            this.projectForm
              .get('gasProvider')
              ?.setValidators([Validators.required]);
          }
          if (
            this.configData?.utilities.some(
              (utility: any) =>
                selectedUtilities.includes(utility.id) &&
                utility.name === 'Water'
            )
          ) {
            this.projectForm
              .get('waterProvider')
              ?.setValidators([Validators.required]);
          }

          if (
            this.configData?.utilities.some(
              (utility: any) =>
                selectedUtilities.includes(utility.id) &&
                utility.name === 'TriSource'
            )
          ) {
            this.projectForm
              .get('triSourceProvider')
              ?.setValidators([Validators.required]);
          }

        }

        //Update the validity status of the form controls
        this.projectForm.get('valveCutofflimit')?.updateValueAndValidity();
        this.projectForm.get('relayCutofflimit')?.updateValueAndValidity();
        this.projectForm.get('gasProvider')?.updateValueAndValidity();
        this.projectForm.get('electricityProvider')?.updateValueAndValidity();
        this.projectForm.get('waterProvider')?.updateValueAndValidity();
        this.projectForm.get('triSourceProvider')?.updateValueAndValidity();
      });
  }

  downloadFile(file: string, filename: string) {
    this.sharedService.downloadUtilityFiles(file, filename);
  }
  updateWalletFields(wallet: boolean): void {
    this.displayfieldsBasedonWalletCheckBox = false;
    this.projectForm.get('minRechargeAmount')?.clearValidators();
    this.projectForm.get('minWalletBalance')?.clearValidators();
    this.projectForm.get('maxRechargeAmount')?.clearValidators();
    if (wallet) {
      this.displayfieldsBasedonWalletCheckBox = true;
      this.projectForm
        .get('minRechargeAmount')
        ?.setValidators([
          Validators.required,
          Helper.numberWithDecimalsValidator(),
        ]);
      this.projectForm
        .get('minWalletBalance')
        ?.setValidators([
          Validators.required,
          Helper.numberWithDecimalsValidator(),
        ]);
      this.projectForm
        .get('maxRechargeAmount')
        ?.setValidators([
          Validators.required,
          Helper.numberWithDecimalsValidator(),
        ]);
    }
    this.projectForm.get('minRechargeAmount')?.updateValueAndValidity();
    this.projectForm.get('minWalletBalance')?.updateValueAndValidity();
    this.projectForm.get('maxRechargeAmount')?.updateValueAndValidity();
  }
  onBillingTypeChange(billingTypeId: string): void {
    let isWalletEnabledControl = this.projectForm.get('IsWalletEnabled');
    let BillGenerationTypeId = this.projectForm.get('BillGenerationTypeId');
    let isvalvecutofdatrControl = this.projectForm.get('ValveCutOffDate');
    let isrelaycutofdateControl = this.projectForm.get('RelayCutOffDate');
    let dueDateControll = this.projectForm.get('DueDate');


    // Find the billing type by ID
    const billingType = this.configData.billingTypes.find(
      (type: any) => type.id === billingTypeId
    );

    isWalletEnabledControl?.setValue(true);
    isWalletEnabledControl?.disable();

    if (billingType) {
      if (billingType.name === 'Prepaid') {
        // Prepaid
        this.isDisplayFieldsBasedOnBillType = false;
        isWalletEnabledControl?.setValue(true);
        isWalletEnabledControl?.disable();
        dueDateControll?.clearValidators();
        this.projectForm.get('billDate')?.clearValidators();
        this.projectForm.get('BillGenerationTypeId')?.clearValidators();
        this.projectForm.get('billDate')?.updateValueAndValidity();
        this.projectForm.get('BillGenerationTypeId')?.updateValueAndValidity();
        isvalvecutofdatrControl?.clearValidators();
        isrelaycutofdateControl?.clearValidators();
        isvalvecutofdatrControl?.updateValueAndValidity();
        isrelaycutofdateControl?.updateValueAndValidity();
        dueDateControll?.updateValueAndValidity();
        this.projectForm.patchValue({
          BillGenerationTypeId: this.configData.billGenerationTypes[1].id,
        });

        // BillGenerationTypeId?.disable();

        let selectedUtilities = this.projectForm.get('SelectedUtilityIds')?.value;

        // Reset validators
        this.projectForm.get('valveCutofflimit')?.clearValidators();
        // this.projectForm.get('valveCutofflimit')?.reset();
        this.projectForm.get('relayCutofflimit')?.clearValidators();
        // this.projectForm.get('relayCutofflimit')?.reset();
        this.projectForm.get('gasProvider')?.clearValidators();
        // this.projectForm.get('gasProvider')?.reset();
        this.projectForm.get('electricityProvider')?.clearValidators();
        // this.projectForm.get('electricityProvider')?.reset();
        this.projectForm.get('waterProvider')?.clearValidators();
        // this.projectForm.get('waterProvider')?.reset();
        this.projectForm.get('triSourceProvider')?.clearValidators();

        this.projectForm.get('gasConsumptionLimit')?.clearValidators();
        this.projectForm.get('waterConsumptionLimit')?.clearValidators();
        this.projectForm.get('electricityEBConsumptionLimit')?.clearValidators();
        this.projectForm.get('electricityDGConsumptionLimit')?.clearValidators();



        const selectedBillingTypeName = this.configData?.billingTypes.find(
          (billingType: any) => billingType.id === this.projectForm.get('billingType')?.value
        )?.name;

        const isBillingTypePrepaid = selectedBillingTypeName === BillingType.PREPAID;


        if (selectedUtilities) {
          if (
            this.configData?.utilities.some(
              (utility: any) =>
                selectedUtilities.includes(utility.id) &&
                utility.name === 'Electricity'
            )
          ) {
            if (isBillingTypePrepaid) {
              this.projectForm
                .get('relayCutofflimit')
                ?.setValidators([
                  Validators.required,
                  Helper.numberWithDecimalsValidator(),
                ]);
            }
            this.projectForm
              .get('electricityProvider')
              ?.setValidators([Validators.required]);
            this.projectForm.get('electricityEBConsumptionLimit')?.setValidators([Validators.required]);
            this.projectForm.get('electricityDGConsumptionLimit')?.setValidators([Validators.required]);
          }
          if (
            this.configData?.utilities.some(
              (utility: any) =>
                selectedUtilities.includes(utility.id) && utility.name === 'Gas'
            )
          ) {
            if (isBillingTypePrepaid) {
              this.projectForm
                .get('valveCutofflimit')
                ?.setValidators([
                  Validators.required,
                  Helper.numberWithDecimalsValidator(),
                ]);
              this.projectForm.get('gasConsumptionLimit')?.setValidators([Validators.required]);
            }
            this.projectForm
              .get('gasProvider')
              ?.setValidators([Validators.required]);
          }
          if (
            this.configData?.utilities.some(
              (utility: any) =>
                selectedUtilities.includes(utility.id) &&
                utility.name === 'Water'
            )
          ) {
            this.projectForm
              .get('waterProvider')
              ?.setValidators([Validators.required]);
            this.projectForm.get('waterConsumptionLimit')?.setValidators([Validators.required]);
          }

          if (
            this.configData?.utilities.some(
              (utility: any) =>
                selectedUtilities.includes(utility.id) &&
                utility.name === 'TriSource'
            )
          ) {
            this.projectForm
              .get('triSourceProvider')
              ?.setValidators([Validators.required]);
          }
        }

        //Update the validity status of the form controls
        this.projectForm.get('valveCutofflimit')?.updateValueAndValidity();
        this.projectForm.get('relayCutofflimit')?.updateValueAndValidity();
        this.projectForm.get('gasProvider')?.updateValueAndValidity();
        this.projectForm.get('electricityProvider')?.updateValueAndValidity();
        this.projectForm.get('waterProvider')?.updateValueAndValidity();
        this.projectForm.get('triSourceProvider')?.updateValueAndValidity();


        this.projectForm.get('gasConsumptionLimit')?.updateValueAndValidity();
        this.projectForm.get('waterConsumptionLimit')?.updateValueAndValidity();
        this.projectForm.get('electricityEBConsumptionLimit')?.updateValueAndValidity();
        this.projectForm.get('electricityDGConsumptionLimit')?.updateValueAndValidity();


      } else if (billingType.name === 'Postpaid') {
        // Postpaid
        this.isDisplayFieldsBasedOnBillType = true;
        this.projectForm.get('valveCutofflimit')?.clearValidators();
        this.projectForm.get('relayCutofflimit')?.clearValidators();

        this.projectForm.get('valveCutofflimit')?.updateValueAndValidity();
        this.projectForm.get('relayCutofflimit')?.updateValueAndValidity();
        dueDateControll?.setValidators([Validators.required]);

        isvalvecutofdatrControl
          ?.setValidators([Validators.required]);
        if (this.isDisplay(UtilityType.ELECTRICITY)) {
          isrelaycutofdateControl
            ?.setValidators([Validators.required]);
        } else {
          isrelaycutofdateControl
            ?.clearValidators();
        }

        isvalvecutofdatrControl?.updateValueAndValidity();
        isrelaycutofdateControl?.updateValueAndValidity();
        dueDateControll?.updateValueAndValidity();

        // isWalletEnabledControl?.setValue(false);
        // isWalletEnabledControl?.enable();
        // BillGenerationTypeId?.enable();
        // if (isWalletEnabledControl) {
        //   const isWalletEnabledValue = isWalletEnabledControl.value;
        //   this.updateWalletFields(isWalletEnabledValue);
        // }
      } else {
        isWalletEnabledControl?.setValue(false);
        isWalletEnabledControl?.enable();
        BillGenerationTypeId?.enable();
      }
    } else {
      console.error('Invalid billingTypeId:', billingTypeId);
    }
  }

  getAllConfigData(): void {
    this.sharedService.getAllConfigData().subscribe((data) => {
      if (
        data.statusCode == 200 &&
        data.message == 'Config data retrieved successfully.'
      ) {
        this.configData = data.data;
        this.configData.waterProviders = this.configData.suppliers.filter(
          (data: any) => data.master.name.trim().toLowerCase() === "water"
        );
        this.configData.gasProviders = this.configData.suppliers.filter(
          (data: any) => data.master.name.trim().toLowerCase() === "gas"
        );
        this.configData.electricityProviders = this.configData.suppliers.filter(
          (data: any) => data.master.name.trim().toLowerCase() === "electricity"
        );
        this.configData.TriSourceProviders = this.configData.suppliers.filter(
          (data: any) => {
            console.log(data.master.name);  // Check value of `name` before filtering
            return data.master.name.trim().toLowerCase() === "trisource";  // Case insensitive comparison
          }
        );
        console.log(this.configData.TriSourceProviders);
        console.log(' this.configData.electricityProviders ', this.configData.electricityProviders);


      }
    });
  }
  parsing(data: any): number {
    return parseInt(data, 10);
  }
  findInvalidControls(form: any): { [key: string]: any }[] {
    const invalidControls = [];
    const controls = form.controls;

    for (const name in controls) {
      if (controls[name].invalid) {
        invalidControls.push({
          controlName: name,
          controlErrors: controls[name].errors,
        });
      }
    }

    return invalidControls;
  }
  saveProject(type: string): void {
    this.submitted = true;
    const invalidControls = this.findInvalidControls(this.projectForm);
    console.log('Invalid controls:', invalidControls);
    if (this.projectForm.valid) {
      this.projectForm.markAsPristine();
      if (
        this.parsing(this.projectForm.value.minRechargeAmount) >
        this.parsing(this.projectForm.value.minRechargeAmount)
      ) {
        return;
      }
      this.submitted = false;
      this.loaderService.setLoading(true);
      // Process form data (convert the date to ISO format before sending to API)
      const projectData = { ...this.projectForm.getRawValue() };
      this.selectedDate = this.convertToUTC(this.projectForm.controls['StartDate'].value)

      // Convert start date to ISO string if it's part of the form data
      if (this.selectedDate) {
        projectData.StartDate = this.selectedDate
      } else {
        projectData.StartDate = null
      }
      // console.log('Invalid controls:', projectData);

      // Process form data (send to backend, etc.)
      // console.log(this.projectForm.value);
      if (this.typeOf == 'Create') {
        this.store.dispatch(
          createProject({ projectData: projectData })
        );
      } else if (this.typeOf == 'Edit') {
        console.log(this.projectForm.value);
        this.store.dispatch(
          updateProject({
            projectData: projectData,
            id: this.projectDetails.id,
          })
        );
      }
    }
  }

  // File Upload
  imageURL: any;
  onUploadSuccess(event: any) {
    setTimeout(() => {
      this.uploadedFiles.push(event[0]);
    }, 0);
  }

  // File Remove
  removeFile(event: any) {
    this.uploadedFiles.splice(this.uploadedFiles.indexOf(event), 1);
  }

  getAllGatewatList() {
    this.store.dispatch(
      getGatewayList({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        id: this.projectId,
        searchTerm: this.gateWaySearch,
        filter: this.gateWayQueryString,
        orderBy: this.orderByQueryOfGatewat,
      })
    );
  }

  private exportSubscription: Subscription = new Subscription();
  getStoreInfo() {
    let getGateway$ = this.store.pipe(select(getGateWayResponse));
    let deletegateway$ = this.store.pipe(select(deleteGateWayResponse));
    let creategatewayResponse$ = this.store.pipe(select(creategatewayResponse));
    let updategateWayResponse$ = this.store.pipe(select(updateGateWayResponse));
    let getProjectsResponse$ = this.store.pipe(select(getProjectDataSuccessResponse));
    this.exportSubscription = this.store
      .pipe(select(getGatewayExportResponse))
      .subscribe((res: any) => {
        if (res) {
          if (res.items.length > 0) {
            const transformedData = res.items.map((item: any) => ({
              GatewaySerialNumber: item.serialNumber,
              name: item.gatewayProvider.name,
              'Wired GateWay': item.isWiredGateWay,
              sttaus: item.isActive,


            }));
            this.PdfexportService.downloadExcel(transformedData, 'gateway');
            this.loaderService.setLoading(false);
          } else {
            this.sharedService.showMessageDialog(
              'No data to display',
              '',
              SweetAlertIcon.ERROR
            );
          }
        }

      });
    let updateProjectResponse$ = this.store.pipe(select(updateProjectResponse));

    updateProjectResponse$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        // this.router.navigate(['/projects']);
        this.getCurrentUserRegisterdProjectData();
        console.log('projectsList', res);
      }
    });

    getProjectsResponse$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res && res.statusCode == 200) {
        this.projectDetails = res.data;
        console.log('projectsList', res);
      }
    });

    getGateway$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.gatewayList = [];
        res.items.forEach((item: any) => {
          this.gatewayList.push({
            id: item.id,
            gatewayProviderId: item.gatewayProvider.id,
            name: item.gatewayProvider.name,
            serialNumber: item.serialNumber,
            isActive: item.isActive,
            isWiredGateWay: item.isWiredGateWay
          });
        });

        // this.gatewayList = [...res.items];
        this.pageSize = res.pageSize;
        this.totalGateWayCount = res.totalCount;
      } else {
        this.gatewayList = [];
      }
      this.loadGatewayTable();
      console.log('gatewayList-->', res, this.gatewayList);
    });

    deletegateway$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.store.dispatch(
          getGatewayList({
            pageIndex: this.currentPage,
            pageSize: this.pageSize,
            id: this.projectId,
            searchTerm: this.gateWaySearch,
            filter: this.gateWayQueryString,
            orderBy: this.orderByQueryOfGatewat,
          })
        );
        this.loadGatewayTable();
        console.log('projectsList', res, this.gatewayList);
      }
    });

    creategatewayResponse$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      // alert(this.projectId);
      // console.log(this.projectId);

      // console.log(history.state.projectDetails);
      this.projectId = history.state?.projectDetails
        ? JSON.parse(history.state.projectDetails)?.id
        : null;
      if (res) {
        this.getAllGatewatList();
      }
      console.log('createGateWay', res);
    });
    updategateWayResponse$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.getAllGatewatList();
      }
      console.log('update Monitoring', res);
    });
  }
  createProjectConfrimModel!: BsModalRef;


  getCurrentUserRegisterdProjectData() {
    this.store.dispatch(getProject({ projectId: this.projectDetails.id }));
  }

  isImageFile(fileName: string | undefined): boolean {
    if (!fileName) {
      return false; // Return false if fileName is undefined
    }
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
    const fileExtension = fileName.split('.').pop()?.toLowerCase();
    return imageExtensions.includes(fileExtension || '');
  }




  confirmMode() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-primary ms-2',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        allowOutsideClick: false,
        title: 'Unsaved Data',
        text: `You're about to leave the project creation process. If you continue, all unsaved data will be lost. Do you wish you proceed? `,
        icon: 'warning',
        confirmButtonText: 'Yes, Continue',
        cancelButtonText: 'No, Cancel',
        showCancelButton: true,
        // timer: 20000, // close automatically after timeout
        // timerProgressBar: true,
      })
      .then((result) => {
        console.log(result, 'result');
        if (result.value) {
        } else {
          this.router.navigate(['/projects/createProject']);
        }
      });
  }

  ngOnDestroy() {
    console.log(this.projectForm.dirty)
    console.log(this.projectId)
    if (this.projectForm.dirty && this.projectId == null) {
      //  this.confirmMode()
    }
    this.store.dispatch(resetGateWaysState());
    this.exportSubscription.unsubscribe(); // Or set it to [] if you prefer an empty array
  }
  protected gatewayPageChange({ skip, take }: PageChangeEvent): void {
    console.log('>>>', skip, take);
    this.selectedRowdata = [];
    this.skip = skip;
    this.pageSize = take;
    this.currentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.store.dispatch(
      getGatewayList({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        id: this.projectId,
        searchTerm: this.gateWaySearch,
        filter: this.gateWayQueryString,
        orderBy: this.orderByQueryOfGatewat,
      })
    );
    this.loadGatewayTable();
  }

  filterData(value: any): void {
    this.gateWaySearch = value;
  }

  public ongatewayFilter(value: any): void {
    // const inputValue = value;
    // this.gateWaySearch = value;
    this.store.dispatch(
      getGatewayList({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        id: this.projectId,
        searchTerm: this.gateWaySearch,
        filter: this.gateWayQueryString,
        orderBy: this.orderByQueryOfGatewat,
      })
    );
  }

  onGatewaySortChange(sort: SortDescriptor[]): void {
    this.gateWayState.sort = sort;
    console.log(this.gateWayState.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.gateWayState.sort
    );
    this.orderByQueryOfGatewat = orderByQuery;
    this.store.dispatch(
      getGatewayList({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        id: this.projectId,
        searchTerm: this.gateWaySearch,
        filter: this.gateWayQueryString,
        orderBy: this.orderByQueryOfGatewat,
      })
    );
  }
  callAllexport() {
    this.store.dispatch(
      getGatewayExport({
        pageIndex: 1,
        pageSize: this.totalGateWayCount,
        id: this.projectId,
        searchTerm: this.gateWaySearch,
        filter: this.gateWayQueryString,
        orderBy: this.orderByQueryOfGatewat,
      })
    );
  }
  exportAll() {
    this.callAllexport();
  }

  public onExport(type: string) {
    if (type === 'Excel') {
      if (this.gatewaygridData.data.length > 0) {
        setTimeout(() => {
          this.hiddenGrid?.saveAsExcel();
        }, 1000);
      }
      else {
        this.sharedService.showMessageDialog(
          'No data to display',
          '',
          SweetAlertIcon.ERROR
        );
      }
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsPDF();
      }, 1000);
    }
    /* if (this.selectedRowdata.length == 0) {
      this.callAllexport();
    } else {
      if (type === 'Excel') {
        setTimeout(() => {
          this.hiddenGrid?.saveAsExcel();
        }, 1000);
      } else if (type === 'Pdf') {
        setTimeout(() => {
          this.hiddenGrid?.saveAsPDF();
        }, 1000);
      }
    } */
  }
  public onGateWayFilterChange(filter: CompositeFilterDescriptor): void {
    this.gateWayState.filter = filter;
    console.log(this.gateWayState);
    const queryString = this.sharedService.createQuery(this.gateWayState);
    this.gateWayQueryString = this.sharedService.createQuery(this.gateWayState);
    this.store.dispatch(
      getGatewayList({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        id: this.projectId,
        searchTerm: this.gateWaySearch,
        filter: this.gateWayQueryString,
        orderBy: this.orderByQueryOfGatewat,
      })
    );
    console.log(queryString);
  }

  private loadGatewayTable(): void {
    const state: State = {
      take: this.pageSize,
      sort: this.sort,
      filter: this.filter,
    };

    const processedData = process(this.gatewayList, state);


    // this.gatewaygridData = {
    //   data: processedData.data,
    //   total: this.totalGateWayCount,
    // };
    this.gatewaygridData = {
      data: processedData.data.map((item: any) => {
        // console.log(item)
        // console.log(item.isWiredGateWay)
        return {
          ...item,
          // Add 'activeStatus' based on 'isActive'
          activeStatus: item.isActive ? 'ON' : 'OFF',
          isWiredGateWay: item.isWiredGateWay ? 'YES' : 'NO'
        };
      }),
      total: this.totalGateWayCount,
    };

  }

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;

    // Check if the element is a table cell or has the class 'k-column-title'
    if (
      element.nodeName === 'TD' ||
      element.classList.contains('k-column-title')
    ) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }
  addNewGateWay() {
    let projectId = this.projectId;
    this.modalRef = this.modalService.show(RpCreateGatewayComponent, {
      initialState: { projectId },
      ...this.config,
    });
  }
  editGateWay(data: any) {
    let gatewayUpdateData = data;
    console.log(data)
    this.modalRef = this.modalService.show(RpCreateGatewayComponent, {
      initialState: { gatewayUpdateData },
      ...this.config,
    });
  }

  deleteGateway(dataItem: any) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-primary ms-2',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        showCancelButton: true,
      })
      .then((result) => {
        if (result.value) {
          this.store.dispatch(deleteGateWay({ id: dataItem.id }));

          /* swalWithBootstrapButtons.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          ); */
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            SweetAlertIcon.INFO
          );
        }
      });
  }

  protected dataStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.loadGatewayTable();
  }

  gatewayOnSelect(event: any) {
    console.log(event.selectedRows);
    var selectedval = event.selectedRows;
    var deselectedVal = event.deselectedRows;
    if (selectedval.length > 0) {
      selectedval.forEach((element: any) => {
        this.selectedRowdata.push(element.dataItem);
        console.log(' selected Values : ', this.selectedRowdata);
      });
    }
    if (deselectedVal.length > 0) {
      deselectedVal.forEach((element: any) => {
        this.deselectedRowdata.push(element.dataItem);
        console.log('deselectedRowdata Values : ', this.deselectedRowdata);
      });

      if (this.deselectedRowdata.length > 0) {
        const idsToRemove = new Set(
          this.deselectedRowdata.map((item: { id: any }) => item.id)
        );

        const filteredArray = this.selectedRowdata.filter(
          (item: { id: unknown }) => !idsToRemove.has(item.id)
        );
        console.log('', filteredArray);
        this.selectedRowdata = filteredArray;
      }
    }
  }

  checkBtnStatus(dataItem: any): boolean {
    if (dataItem.isActive === 'Yes' && !dataItem.meterCurrentStatus) {
      return true; // Button for turning on, so toggle off
    }
    if (dataItem.offRequest === 'Yes' && dataItem.meterCurrentStatus) {
      return true; // Button for turning off, so toggle on
    }
    return dataItem.meterCurrentStatus; // Otherwise, keep the current status
  }

  toggleMeterStatus(event: any, dataItem: any) {
    let request = {
      id: dataItem.id,
      Status: event,
    };
    console.log(request);
    this.store.dispatch(SetGatewayStatus({ request: request }));
  }
  public gatewayonExport(type: string) {
    // if (this.selectedRowdata.length == 0) {
    //   this.getTotalGatewatList();
    // }
    if (type === 'Excel') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsExcel();
      }, 1000);
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsPDF();
      }, 1000);
    }
  }
}
